import React from "react";

import Layout from "components/Layout";
import SEO from "components/SEO";
import Posts from "components/Posts";
import WebDesign from "components/WebDesign";
import AppUx from "components/AppUx";
import VideoGames from "components/VideoGames";
import VirtualReality from "components/VirtualReality";
import AugmentedReality from "components/AugmentedReality";
import Interaction from "components/Interaction";

const BlogPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Work" />
      <Posts />
      <WebDesign />
      <AppUx />
      <VideoGames />
      <VirtualReality />
      <AugmentedReality />
      <Interaction />
    </Layout>
  );
};

export default BlogPage;
